var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('vue-snotify'),_c('v-card',[_c('v-card-title',{staticClass:"cyan darken-1 card-sipymex"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',{staticClass:"white--text"},[_vm._v("Deudas")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}})],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('br'),_c('v-data-table',{attrs:{"z-index":"100","headers":_vm.headers,"items":_vm.debts,"search":_vm.search,"footer-props":{
        itemsPerPageText: 'Filas por página',
        itemsPerPageAllText: 'todos',},"no-data-text":"No hay datos disponibles","no-results-text":"No hay datos disponibles"},scopedSlots:_vm._u([{key:"item.name_customer",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getNameCustomerByIdContract(item.id_contract)))])]}},{key:"item.name_plan",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getNamePlanByIdContract(item.id_contract)))])]}},{key:"item.billing_period",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDayBillingPeriodsByIdContract(item.id_contract)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red darken-2"}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Mensaje")]),_c('v-card-text',[_c('div',{staticClass:"text-h7 pa-6"},[_vm._v(" ¿Estás seguro que deseas eliminar la deuda? ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"primary",attrs:{"text":""},on:{"click":function($event){dialog.value = false;
                  _vm.deleteItem(item);}}},[_vm._v("Aceptar")])],1)],1)]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }